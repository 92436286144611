@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide the default scrollbar */
body::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
  }
  
  body::-webkit-scrollbar-track {
    background-color:none; /* Set the track color */
  }
  
  body::-webkit-scrollbar-thumb {
    background-color:none /* Set the thumb color with transparency */
  }
  